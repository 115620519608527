@use '/src/utils/globals';

.BarnListPage {
  margin-bottom: 5rem;
  margin-left: 3rem;
  margin-right: 3rem;
  @media (max-width: globals.$collapse-panel-on-width) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .actionBox {
    border-radius: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 4px;
    vertical-align: middle;
    margin: auto;
    text-align: center;
    padding-top: 0rem;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px;
    }
    img {
      vertical-align: middle;
      margin: auto;
      display: inline;
    }
  }
}
