@use '/src/utils/globals';
@import url('https://use.typekit.net/lgq3bba.css');

.App {
  background: #fff;

  .SideBarView {
    height: 100vh;
    width: 15rem;
    float: left;
    position: fixed;

    z-index: 10;
  }

  .rightSide {
    height: 100vh;
    padding-left: 15rem;
  }

  @media (max-width: globals.$collapse-panel-on-width) {
    .rightSide {
      padding-left: 0rem;
    }
  }

  .pageSpace {
    height: calc(100vh - 4rem);
    overflow-y: scroll;
  }
}

h1 {
  color: #1a1f36;
  font-weight: 700;
}

.App-sidebar {
  flex: 1;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.loadingPage {
  width: 100vw;
  height: 100vh;
  padding-bottom: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .LoadingView {
    width: 30rem;
    padding-left: 1rem;
  }
}
