.BinSetSection {
  margin: 1rem 0 1.5rem;

  &-heading {
    margin-bottom: 0.5rem;
  }

  &-content {
    display: flex;
    align-items: center;
  }

  &-symbolContainer {
    display: flex;
    align-items: center;
  }

  &-detailsContainer {
    margin-left: 1rem;
  }

  &-detailsRow {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }

  &-detailsLabel {
    font-weight: 600;
    margin-right: 0.25rem;
  }

  &-loader {
    margin-right: 1rem;

    &--small {
      display: inline-block;
      width: 3.75rem;
    }

    &--medium {
      display: block;
      width: 8rem;
      margin: 1rem 0 1rem;
    }

    &--tall {
      display: inline-block;
      height: 6rem;
    }
  }
}
