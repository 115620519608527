.FeedFloProgress {
  position: relative;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 2em;
  width: 100%;

  display: inline-block;
  overflow: hidden;
  background-color: #dddbdd;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  .inner {
    width: 0%;
    height: 100%;
    background-color: rgb(63, 190, 116);
  }
}
