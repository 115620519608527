.BarnSettingsTab {
  &-barnSettings {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    row-gap: 1.25rem;
  }

  &-rowLabel {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  &-rowField {
    max-width: 3.25rem;
    font-size: 1.1rem;
    margin: 0 0.5rem;
  }

  &-rowInput {
    padding: 0.35rem;
    text-align: right;
  }

  &-subheading {
    font-size: 1.5rem;
    padding: 0.5rem 0;
    margin-top: 0;
  }

  &-loader {
    margin-right: 1rem;

    &--small {
      width: 3rem;
    }

    &--medium {
      width: 12rem;
    }
  }
}
