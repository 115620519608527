.StatusBadge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: fit-content;
  font-size: 0.7rem;
  font-weight: 700;
  background-color: rgb(230, 230, 230);
  color: gray;
  padding: 0.2rem;
  border-radius: 0.2rem;

  .label {
    margin-right: 0.3rem;
  }

  img {
    height: 1rem;
    display: inline-block;
    vertical-align: bottom;
  }
  &.success {
    color: #0e6245;
    background-color: #cbf4c9;
  }
  &.warning {
    color: #9c3f0f;
    background-color: #f8e5ba;
  }
  &.error {
    color: #b10a0a;
    background-color: #ffd8d8;
  }
}
