.FeedFloDatePicker {
  position: absolute;
  margin-top: 2rem;
  z-index: 9;
  background-color: #fff;

  display: flex;
  flex-direction: column;

  border: 0.063rem solid #d9dce1;
  border-radius: 0.25rem;
  width: fit-content;
  box-shadow: 0rem 0.25rem 0.25rem 0rem #00000040;
  .header {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    div {
      display: inline-flex;
      flex-direction: column;

      div {
        font-size: 0.875rem;
        margin-bottom: 0.313rem;
        margin-left: 0.125rem;
        line-height: 1.066rem;
        color: #7a7f83;
      }
    }

    input {
      width: 6rem;
      border: 0.063rem solid #e3e8ee;
      box-sizing: border-box;
      border-radius: 0.25rem;
      padding: 0.188rem 0.5rem;
    }
  }
  .Calendar {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
