.CheckBox {
  -webkit-appearance: none;
  appearance: none;
  user-select: none;
  background: #ffffff;
  border-radius: 0.25rem;
  border: 0.063rem solid rgba(60, 66, 87, 0.16);
  box-shadow: 0px 0.063rem 0.063rem rgba(0, 0, 0, 0.12);
  width: 1rem;
  height: 1rem;
}

.CheckBox:hover {
  background: rgba(226, 226, 226, 0.705);
}

.CheckBox:checked {
  border: 0.063rem solid #27ae60;
  // ideally we want checkbox.svg to be in the public folder but react does not let us link if it is outside the src/
  // feel free to change if better way is found
  background: url(./checkbox.svg);
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  background-position: center;
}
