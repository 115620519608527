@use '/src/utils/globals';

.TopBar {
  position: relative;
  height: 4rem;
  border-bottom: #e3e8ee;
  border-bottom-width: 0.15rem;
  border-bottom-style: solid;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;

  div {
    margin: 0.5rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding: 0.5rem;
    height: fit-content;
    border-radius: 0.5rem;
    &:hover {
      background-color: rgb(233, 233, 233);
    }
  }
  .buttons {
    padding: 0;
    border: none;
    background: none;
    &.left {
      float: left;
    }
    &.right {
      float: right;
    }

    &.menu {
      @media (min-width: globals.$collapse-panel-on-width) {
        display: none;
      }
    }
  }
}
