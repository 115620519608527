.FeedFloNumInput {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }

  label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.188rem;
    display: flex;
    flex-direction: column;
  }

  .textBox {
    border: 0.063rem solid #c4c4c4;
    border-radius: 0.25rem;
    font-size: 13px;
  }
}
