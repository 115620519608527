.DropDown {
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.188rem;
  }

  .dropDownHeader {
    width: 100%;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border: 1px solid #c4c4c4;
    border-radius: 4px;

    .selected {
      align-self: center;
      font-size: 13px;
      padding: 0.5rem;
    }

    .icon {
      align-self: center;
      margin: 0px 8px;

      &.showList {
        transform: rotate(180deg);
      }
    }
  }

  .list {
    position: absolute;
    width: 100%;
    z-index: 9;
    background-color: #fff;
    top: 2.3rem;
    white-space: normal;

    display: flex;
    flex-direction: column;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;

    .listItem {
      font-size: 13px;
      padding: 0.5rem;
    }

    .selected {
      background-color: #6fcf9750;
    }

    .listItem:hover {
      background-color: #6fcf97a1;
    }
  }
  .with-label {
    top: 3.8rem;
  }
}
