.SystemHealthPage {
  margin-left: 3rem;
  margin-right: 3rem;
  .placeHolder {
    display: flex;
    justify-content: center;
    padding: 5rem;
  }

  h2 {
    position: static;
    width: 1090px;
    height: 309px;
    left: 0px;
    top: 0px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-indent: 29px;

    /* Grey 900 */

    color: #1a1f36;
    margin: 0px 20px;
  }
}
