.NotificationSettings {
  border: 1px solid #d9dce1;
  border-radius: 4px;
  min-width: fit-content;
  width: 100%;
  padding-bottom: 1rem;

  > .notifDivider {
    border-bottom: 1px solid #d9dce1;
    margin: 5px 0px;
  }

  > .headerNotif {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 1rem;
    > .alertTypeHeader {
      font-weight: 600;
    }

    > .smsHeader {
      font-weight: 600;
    }
    > .emailHeader {
      font-weight: 600;
    }
  }
  > .rowNotif {
    display: flex;
    flex-direction: row;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    > .alertTypeRow {
      font-weight: 600;
    }
    > .emailRow {
      margin-right: 1.6rem;
    }
  }
}

.NotificationSection {
  > .orgNotif {
    display: flex;
    flex-direction: row;
    > .orgNotifName {
      font-weight: 600;
    }
  }

  > .barnNotif {
    display: flex;
    flex-direction: row;
    font-size: 14px;
  }
}

// TODO: create a cell padding variable
.alertColNotif {
  margin-left: 2rem;
  padding: 0.375rem;
}

.smsColNotif {
  margin-left: auto;
  margin-right: 2rem;
  padding: 0.375rem;
  min-width: 3.75rem;
  text-align: center;
  justify-content: center;
}

.emailColNotif {
  margin-right: 2rem;
  float: right;
  padding: 0.375rem;
  min-width: 3.75rem;
  text-align: center;
}

.spacing {
  margin-left: 2.4rem;
  margin-right: 3.5rem;
  margin-bottom: 0rem;
}
.alignList {
  & .list {
    left: 50%;
    right: auto;
    transform: translateX(-70%);
    text-align: left;
  }
}
