.FeedFloPercent {
  border-radius: 0.25rem;
  padding: 0.125rem 0.375rem;
  display: flex;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 500;

  &.aboveRange {
    background-color: #f8e5ba;
    color: #9c3f0f;
  }

  &.withinRange {
    background-color: #cbf4c9;
    color: #0e6245;
  }

  &.belowRange {
    background-color: #ffd8d8;
    color: #b10a0a;
  }
}
