.FeedFloToolTip {
  display: flex;
  flex-direction: row;
  width: fit-content;

  .tooltip {
    cursor: pointer;
    position: relative;
    display: inline-block;
    :hover {
      box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 2px 5px;
    }
  }

  .tooltip .tooltiptext {
    /* Position the tooltip */
    position: absolute;
    z-index: 1;

    cursor: default;
    visibility: hidden;
    background-color: #fff;
    width: fit-content;
    white-space: nowrap;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #a3acb9;
    bottom: 1.8rem;
    left: 55%;
    right: auto;
    transform: translateX(-44%);
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
    font-size: 0.625rem;

    //make comic cloud underneath
    &:before {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      top: 15.8px;
      right: 37px;
      transform: rotate(225deg);
      border-left: 1px solid #a3acb9;
      border-top: 1px solid #a3acb9;
      background-color: #fff;
    }
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    box-shadow: none;
  }
}
