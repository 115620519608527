.FeedFloChart {
  svg {
    width: 100%;
  }
  position: relative;
  .tooltipInfo {
    > .tooltipMass {
      font-size: 0.75rem;
      line-height: 0.914rem;
    }
    > .tooltipDate {
      font-size: 0.625rem;
      line-height: 0.746rem;
    }
  }
}
