.UserDropDown {
  position: absolute;
  right: 1rem;
  z-index: 9;
  border: #d9dce1;
  filter: drop-shadow(0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: column;

  .button:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .button:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .button {
    cursor: pointer;
    left: 0;
    z-index: 9;
    background: #ffffff;
    text-align: left;
    padding: 0.45rem 0.8rem;
    border: 0.125rem solid #d9dce1;
    border-color: #d9dce1;
    border-bottom: 0.5rem;
    color: #565758;
    display: block;
    font-weight: 600;
    text-decoration: none;
    &:hover {
      background-color: #27ae60;
      border-color: #27ae60;
      color: #ffffff;
    }
    span {
      vertical-align: middle;
      vertical-align: middle;
      padding-right: 0.3em;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 100%;
    background-color: #d9dce1;
    border-color: #d9dce1;
    color: #ffffff;
  }
}
