.RadioButton {
  -webkit-appearance: none;
  appearance: none;
  user-select: none;
  background: #ffffff;
  border: 0.1rem solid #27ae60;
  border-radius: 0.5rem;
  box-shadow: 0px 0.063rem 0.063rem rgba(0, 0, 0, 0.12);
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  margin-top: -0.063rem; // centers with text
  &.grey {
    border: 0.1rem solid #a9a9a9;
  }
}

.RadioButton:hover {
  background: rgba(226, 226, 226, 0.705);
}

.RadioButton:checked {
  // ideally we want checkbox.svg to be in the public folder but react does not let us link if it is outside the src/
  // feel free to change if better way is found
  background: url(./filled-radio.png);
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  background-position: center;
  &.grey {
    filter: invert(89%) sepia(14%) saturate(0%) hue-rotate(160deg) brightness(92%) contrast(83%); //sets button to gray since default is white
    -webkit-filter: invert(89%) sepia(14%) saturate(0%) hue-rotate(160deg) brightness(92%) contrast(83%);
  }
}
