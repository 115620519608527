.BarnConsumptionTileView {
  display: flex;
  flex-direction: row;
  width: 20rem;
  justify-content: space-between;
  color: #1a1f36;

  .left {
    display: flex;
    flex-direction: column;

    div {
      margin: 0.219rem;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    div {
      margin: 0.219rem;
    }
  }

  .title {
    font-size: 0.875rem;
    line-height: 1.066rem;
    font-weight: 600;
    color: #4f566b;
  }

  .value {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.523rem;
  }

  .expected {
    opacity: 0.3;
  }

  .unit {
    font-size: 0.625rem;
    line-height: 0.761rem;
  }
}
