@use '/src/utils/globals';

.BarnConsumptionTab {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 3rem;

  @media (max-width: globals.$collapse-panel-on-width) {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .BarnConsumptionHolder {
    .FeedFloChart {
      height: 20rem;
    }
  }
  .FeedLineConsumptionHolders {
    .FeedFloChart {
      height: 20rem;
    }
  }
  @media (max-width: globals.$collapse-panel-on-width) {
    .BarnConsumptionHolder {
      .FeedFloChart {
        height: 8rem;
      }
    }
    .FeedLineConsumptionHolders {
      .FeedFloChart {
        height: 8rem;
      }
    }
  }
  .topHalf {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    @media (max-width: globals.$collapse-panel-on-width) {
      flex-direction: column;
    }

    .topLeftSide {
      flex: 1.5;
      margin-top: 1.5rem;

      .headers {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        .dateRangeButton {
          display: flex;
          flex-direction: row;

          border: 0.063rem solid #d9dce1;
          border-radius: 0.25rem;
          width: fit-content;

          user-select: none;
          cursor: pointer;
          white-space: nowrap;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 2px 5px;
          }
          div {
            padding: 0.25rem 0.75rem;
          }

          .icon {
            display: flex;
            align-self: center;
            padding-bottom: 0.438rem;
            padding-right: 0.25rem;
          }

          .text {
            color: #3c4257;
            font-weight: 500;
            padding-left: 0.25rem;
          }
        }
      }
      .headers > * {
        margin-right: 0.5rem;
      }
    }

    .topRightSide {
      flex: 1;
      .tiles > * {
        border-bottom: solid rgb(223, 223, 223) 1px;
        padding-top: 0.5rem;
        padding-bottom: 2rem;
      }
      .tiles > :last-child {
        border-bottom: none;
      }

      .statHeader {
        background-color: rgba(111, 207, 151, 0.08);
        font-weight: 600;
        padding-left: 1rem;
        padding-top: 2rem;
      }
    }
  }

  .bottomHalf {
    display: flex;
    flex: 1.5;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: start;
    @media (max-width: globals.$collapse-panel-on-width) {
      flex-direction: column;
    }

    .FeedLineConsumptionHolders {
      width: 50%;
      padding: 1rem;
      border-color: #e3e8ee;
      border-style: solid;
      border-width: 0.1rem;
      margin-bottom: -0.0625rem;
      @media (max-width: globals.$collapse-panel-on-width) {
        width: 100%;
      }
    }

    .FeedLineConsumptionHolders:nth-child(odd) {
      margin-right: -0.0625rem;
    }
  }
}

@media (max-width: globals.$collapse-panel-on-width) {
  .topRightSide {
    display: none;
  }
}
