.FeedFloWatchListRow {
  border: 1px solid #d9dce1;
  border-radius: 4px;
  min-width: fit-content;
  width: 100%;
  margin: 0.5rem;

  .input-field {
    display: flex;
    width: 100%;
    padding: 0.625rem;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;

    .feedFloIcon {
      margin-right: 1rem;
      filter: invert(54%) sepia(29%) saturate(1091%) hue-rotate(93deg) brightness(96%) contrast(86%);
      cursor: pointer;
    }
  }

  .tooltip {
    position: relative;
    display: inline;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    top: -3rem;
    left: -2.5rem;
    text-align: center;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
