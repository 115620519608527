.FeedFloNewAccessKeyBlur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(104 104 104 / 80%);
  z-index: 100;
}
.FeedFloNewAccessKey {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px solid #d9dce1;
  border-radius: 4px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50rem;
  height: fit-content;
  background-color: #fff;
  z-index: 101;

  > .row {
    display: flex;
    flex-direction: row;
    margin: 1rem 0rem;
  }

  .note .FeedFloTextInput .textBox:focus-visible {
    outline: 1px solid #27ae60;
  }

  .note.error .FeedFloTextInput .textBox {
    outline: 1px solid #ff0033;
  }

  .explanation {
    margin-top: -0.8rem;
    margin-left: 0.2rem;
    font-size: 11px;
    color: #647279;
  }
  .center {
    margin-top: 2rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
  }
  .roleExplanation {
    margin-left: 4rem;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    font-size: 11px;
    color: #647279;
  }
  .roleTitle {
    color: #647279;
  }

  .scopes {
    display: flex;
    flex-direction: column;
    .error .FeedFloScopesList {
      outline: 1px solid #ff0033;
      border-radius: 8px;
    }
  }

  .scopeDescription {
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    font-size: 12px;
  }

  .expiry {
    width: 30rem;
    position: relative;
    z-index: 10;
  }

  .scopeTitle {
    margin-top: 0rem;
    font-weight: 600;
  }

  .roleBox {
    display: flex;
    flex-direction: row;
    border: 1px solid #d9dce1;
    border-radius: 4px;
    padding: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: fit-content;
  }

  label {
    font-weight: 600;
    font-size: 1rem;
    margin-left: 0.188rem;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: 0;
    .green .FeedFloButton {
      color: #fff;
      background-color: #219653;
      margin-right: 1rem;
    }
    .FeedFloButton {
      font-size: larger;

      padding: 0.5rem;
    }
  }
}
