@use '/src/utils/globals';

.ApiAccessPage {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 3rem;
  @media (max-width: globals.$collapse-panel-on-width) {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .h1 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;
    border-bottom: solid rgb(223, 223, 223) 1px;
  }

  .ButtonHolder {
    display: flex;
    margin-bottom: 1em;
    flex-direction: row-reverse;
  }
  .FeedFloButton {
    margin-left: 1em;
    &.primary {
      background-color: #219653;
      color: white;
      font-weight: 400;
    }

    &.secondary {
      background-color: #fff;
      color: #3c4257;
    }
    float: right;
    margin-top: 0rem;
    .feedFloIcon {
      margin-top: 0.35em;
      margin-right: 0.4em;
    }
  }

  .APIInfoTile {
    margin-bottom: 1em;
  }
}
