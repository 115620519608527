.FeedFloTable {
  a {
    color: #1a1f36;
  }
  .cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.s {
      min-width: 4rem;
      width: 4rem;
      height: 2rem;
    }
    &.m {
      min-width: 8rem;
      width: 10rem;
      height: 2rem;
    }
    &.l {
      min-width: 12rem;
      width: 20rem;
    }
    &.g {
      min-width: 4rem;
      flex-grow: 1;
    }
  }

  // .cell:hover {
  //   overflow: visible;
  //   z-index: 2;
  // }

  // .cell:hover span {
  //   background-color: #fff;
  //   display: inline-block;
  //   height: fit-content;
  // }

  .header {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 0.9rem;
    border-bottom-color: #e3e8ee;
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    .cell {
      padding-top: 1rem;
      padding-bottom: 1;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    border-bottom-color: #e3e8ee;
    border-bottom-width: 0.1em;
    border-bottom-style: solid;
    .cell {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      padding-left: 0.2rem;
    }
    .skeleton-box {
      display: inline-block;
      height: 1em;
      position: relative;
      overflow: hidden;
      background-color: #dddbdd;
      width: 70%;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: '';
      }

      @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
      }
    }
  }

  .footer {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;

    .results {
      flex-grow: 1;
    }

    .page-number {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .previous {
      margin-right: 0.7rem;
    }
  }

  .emptyImageHolder {
    width: 100%;
    height: 60em;
    justify-content: center;
    display: flex;
    padding: 10rem;
    div img {
      width: 20em;
    }
  }
}
