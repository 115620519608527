.BinSymbol {
  display: inline-flex;
  flex-direction: column;
  margin-right: 1rem;

  &-symbolContainer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &-name {
    position: absolute;
    color: white;
    font-size: 1.5rem;
    top: 1.5rem;
  }

  &-capacity {
    text-align: center;
    font-size: 0.7rem;
  }

  &-loader {
    &--small {
      display: inline-block;
      width: 3.75rem;
    }

    &--medium {
      display: block;
      width: 8rem;
      margin-bottom: 1rem;
    }

    &--tall {
      display: inline-block;
      width: 3.75rem;
      height: 6rem;
      margin-bottom: 0.75rem;
    }
  }
}
