.FeedLineConsumptionTile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2rem;

  .left {
    display: flex;
    flex-direction: column;
    .topLeft {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .line {
    font-weight: 600;
    font-size: 0.875rem;
    color: #4f566b;
    margin-right: 1rem;
    display: flex;
    .name {
    }
    .StatusBadge {
      margin-left: 1rem;
    }
  }
  .usage {
    font-size: 1.25rem;
  }

  .unit {
    font-size: 0.625rem;
    line-height: 0.761rem;
  }

  .link {
    text-decoration: none;
    color: #219653;
    font-weight: 600;
    font-size: 0.875rem;
    text-align: right;

    &:hover {
      text-decoration: underline;
    }
  }

  .expected {
    color: #697386;
    font-size: 1rem;
  }
}
