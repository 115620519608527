.FeedFloNotificationsOptions {
  width: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }

  .dropHeader {
    display: flex;
    flex-direction: column;
    justify-items: left;
    align-items: left;
    padding: 0.2rem;
    font-size: 14px;
  }

  .radioButton {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;

    padding-top: 0.3rem;
    .doNotDisplay {
      display: none;
    }
  }

  .textInput {
    display: flex;
    flex-direction: row;
    width: 2rem;
    margin-right: 2.5rem;
    margin-left: 0.2rem;
    text-indent: 0.2rem;

    &.error label .textBox {
      border-color: #ff0033;
    }
  }

  .align {
    margin-left: 1.95rem;
  }

  .greyTitle {
    opacity: 0.5;
    margin-left: 2rem;
  }

  .errorText {
    color: #ff0033;
    font-weight: 600;
  }
  .default-text {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }

  .alignSetText {
    margin-left: 0.2rem;
  }
  .selectAll:not(:last-child) {
    border-bottom: 0.1rem solid #d9dce1;
  }

  .optionsheader {
    width: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      align-self: center;
      font-size: 13px;
    }
    // Get any colour to transform:
    // https://stackoverflow.com/questions/42966641/how-to-transform-black-into-any-given-color-using-only-css-filters/43960991#43960991
    .chat {
      filter: invert(53%) sepia(3%) saturate(4%) hue-rotate(319deg) brightness(95%) contrast(87%); //sets chat to gray since default is white
      -webkit-filter: invert(53%) sepia(3%) saturate(4%) hue-rotate(319deg) brightness(95%) contrast(87%);
    }
    .icon {
      align-self: center;
      margin: 0rem 0.5rem;
      cursor: pointer;
      &.isSet {
        filter: invert(54%) sepia(29%) saturate(1091%) hue-rotate(93deg) brightness(96%) contrast(86%); // green on click
      }
      &.error {
        user-select: none;
        filter: invert(21%) sepia(93%) saturate(6982%) hue-rotate(343deg) brightness(112%) contrast(121%);
      }
    }
  }

  .list {
    position: absolute;
    width: 12rem;
    z-index: 9;
    background-color: #fff;
    top: 2rem;
    white-space: normal;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid #d9dce1;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
