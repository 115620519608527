@use '/src/utils/globals';

.FeedFloStatTitle {
  display: flex;
  flex-direction: row;
  background-color: rgba(111, 207, 151, 0.08);
  width: 100%;

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  div {
    padding: 0.2rem;
    padding-left: 0.5rem;
  }

  .left {
    flex: 5;

    .title {
      color: #4f566b;
      font-weight: 600;
      font-size: 0.875rem;
    }

    .value {
      font-size: 1.25rem;
    }

    .subtitle {
      color: #4f566b;
      font-size: 0.625rem;
    }
  }

  .right {
    flex: 1;
    display: flex;
    align-self: center;
    justify-content: center;
  }
}
