@use '/src/utils/globals';

.BarnEquipmentTab {
  &-subheading {
    font-size: 1.5rem;
    padding: 0.5rem 0;
    margin-top: 0;
  }

  &-binSetsContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    @media (max-width: globals.$two-columns-on-width) {
      grid-template-columns: repeat(1, minmax(max-content, 1fr));
    }
  }
}
