.FirmwarePage {
  margin-left: 3rem;
  margin-right: 3rem;
  .deviceFW {
    width: 20rem;
  }
  .FirmwareUploadController {
    .row {
      display: flex;
      align-items: center;
      & > div {
        margin: 1rem;
        vertical-align: middle;
        &.grow {
          flex-grow: 1;
        }
      }
    }
  }

  .FirmwareStreamController {
    .fw {
      margin: 1rem;
    }
  }

  .DeviceFirmwareController {
    .deviceFW {
      margin: 1rem;
    }
  }

  .fw {
    .spacer {
      margin-left: 1rem;
    }

    .fwHistory {
      padding-bottom: 1rem;

      .live {
        color: #e11f1f;
        font-weight: 600;
      }
      .tag {
        font-family: monospace;
      }
      .download {
        color: blue;
        cursor: pointer;
        font-family: monospace;
      }
    }

    .fwHistoryWindow {
      height: 10rem;
      overflow-y: scroll;
      width: 15rem;
      padding: 0.3rem;
      margin-left: 0.3rem;
      background-color: rgb(250, 250, 250);
      border-radius: 0.3rem;
      border: solid rgb(155, 155, 155) 0.15rem;
    }
  }
}
