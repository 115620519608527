.APIInfoTile {
  display: flex;
  flex-direction: row;
  border: 2px solid #d9dce1;
  border-radius: 8px;
  width: 100%;

  .left {
    flex: 5;

    padding: 0.5rem;
    padding-left: 1rem;

    .topLine {
      display: flex;
      font-size: 0.875rem;
      padding-right: 0.2rem;
      .type {
        color: #27ae60;
        font-weight: 500;
        padding-right: 0.2rem;
      }
      .permissions {
        font-style: italic;
      }
    }
    .bottomLine {
      font-size: 0.625rem;
      display: flex;
    }
    .date {
      margin-left: 0.2rem;
      font-weight: 600;
      font-style: italic;
    }
  }

  .right {
    display: flex;
    margin-right: 0.5rem;
    align-self: center;
    justify-content: center;
    .tooltip .tooltiptext {
      transform: translateX(-50%);
    }
  }
}
.changeBorder {
  border-top: 0px;
  border-radius: 0px 0px 8px 8px;
}

.ApiGeneratedKey {
  display: flex;
  flex-direction: row;
  background-color: #57bb82;
  border: 2px solid #d9dce1;
  border-bottom: 0px;
  border-radius: 8px 8px 0px 0px;
  width: 100%;

  .topLine {
    display: flex;
    font-size: 0.875rem;
    padding: 0.5rem;
    padding-left: 1rem;
    width: 100%;
    padding-right: 1rem;

    .keyText {
      color: #fff;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .keyColor {
    * {
      width: 1.2rem;
      filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(90deg) brightness(500%) contrast(101%);
    }
  }
  .FeedFloToolTip .tooltip .tooltiptext {
    text-align: center;
    transform: translateX(-50%);
    left: 50%;
    &:before {
      right: 25px;
    }
  }
}
