.FeedFloTabs {
  display: flex;
  flex-direction: row;

  &-tabLink {
    color: inherit;
    text-decoration: none;
  }

  & > div,
  & > a {
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    border-bottom-color: #e3e8ee;
    color: #697386;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    padding: 0px;
    padding-bottom: 0.5rem;

    cursor: pointer;
    &.spacer {
      width: 2rem;
      cursor: inherit;
    }
    &.grower {
      flex-grow: 1;
      cursor: inherit;
    }
    &.selected {
      color: #219653;
      border-bottom-style: solid;
      border-bottom-width: 0.2rem;
      border-bottom-color: #219653;
    }
    &.selected {
      color: #219653;
      border-bottom-style: solid;
      border-bottom-width: 0.2rem;
      border-bottom-color: #219653;
    }
  }
}
