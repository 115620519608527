.BarnStatusPage {
  margin-left: 3rem;
  margin-right: 3rem;

  .farmHolder {
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;

    a {
      text-decoration: none;
    }
    .spacingForTiles {
      margin: 0.625rem;

      .barnTile {
        width: 10rem;
        height: 10rem;
        background-color: #f2f2f2;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 1rem;
        text-align: center;
        padding: 1rem;
        .title {
          text-decoration: none;
          text-transform: uppercase;
          color: #697386;
          height: 3rem;
        }
      }
    }
  }
}
