@use '/src/utils/globals';
.AlertPage {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 5rem;
  @media (max-width: globals.$collapse-panel-on-width) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .farmLink {
    //text-decoration: none;
    color: black;
  }
}
