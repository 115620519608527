@use '/src/utils/globals';

.SideBarView {
  background: #f9f9f9;
  width: 15rem;
  height: 30rem;
  border-right: #e3e8ee;
  border-right-width: 0.15rem;
  border-right-style: solid;
  transition: left 0.5s;

  li {
    list-style: none;
  }

  .org {
    margin: 1rem;
  }
  .whiteCircle {
    background-color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(60, 66, 87, 0.08);
    display: inline-block;
    text-align: center;
    line-height: 2rem;
  }
  .orgName {
    display: inline-block;
    padding-left: 0.7rem;
    margin-top: 0.1rem;
    vertical-align: top;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1a1f36;
    padding-top: 0.2rem;
  }
}

@media (max-width: globals.$collapse-panel-on-width) {
  .SideBarView {
    left: -15rem;
    &.open {
      left: 0rem;
    }
  }
}
