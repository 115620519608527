.GeneralSettings {
  border: 1px solid #d9dce1;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 100%;

  > .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 14px 22px;
  }

  .row > * {
    width: 100%;
    margin: 0px 10px;
  }

  // Phone Number Input Box
  .react-tel-input {
    font-size: 13px;
    width: 100%;
  }

  label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.188rem;
  }

  .react-tel-input .flag-dropdown {
    position: absolute;
    border: 0.063rem solid #c4c4c4;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .react-tel-input .form-control {
    width: 100%;
    border: 0.063rem solid #c4c4c4;
    border-radius: 0.25rem;
  }
}

.loadingForm {
  border: 1px solid #d9dce1;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  height: fit-content;
  width: 100%;

  > .leftCol {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;

    .leftSection {
      display: flex;
      flex-direction: column;
      margin: 14px 22px;
      > .header {
        height: 28px;
        width: 148px;
      }

      > .box {
        height: 28px;
      }
    }
  }

  > .rightCol {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;

    .rightSection {
      display: flex;
      flex-direction: column;
      margin: 14px 22px;
      > .header {
        height: 28px;
        width: 148px;
      }

      > .box {
        height: 28px;
      }
    }
  }

  .skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
