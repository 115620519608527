body {
  margin: 0;
  font-family: proxima-nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

ul {
  padding-inline-start: 0px;
}

.customScroll::-webkit-scrollbar {
  width: 0.5rem; /* width of the entire scrollbar */
}

.customScroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0); /* color of the tracking area */
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: rgb(149, 149, 149); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgba(0, 0, 0, 0); /* creates padding around scroll thumb */
}
