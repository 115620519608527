.FeedFloButton {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  width: fit-content;

  padding: 0.2rem 0.7rem;

  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 0.3rem;
  font-size: 0.9rem;

  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 2px 5px;
  }
  &:active {
    box-shadow: #0000001f 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 0.5px 1px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
    user-select: none;
  }

  &.primary {
    background-color: #fff;
    color: #3c4257;
  }

  &.secondary {
    background-color: #27ae60;
    color: #fff;
  }
}
