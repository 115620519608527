.DeleteConfirmationBlur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(104 104 104 / 80%);
  z-index: 100;
}
.DeleteConfirmation {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px solid #d9dce1;
  background-color: #fff;
  border-radius: 4px;
  padding: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 8rem;
  z-index: 101;
  .revokeDescription {
    font-size: 14px;
    white-space: nowrap;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    justify-content: center;
    .red .FeedFloButton {
      color: #fff;
      background-color: #eb5757;
      margin-left: 5.8rem;
      white-space: nowrap;
    }
    .FeedFloButton {
      font-weight: 500;
    }
  }
}
