.SettingsPage {
  margin: 0 3rem;
  padding-bottom: 3rem;

  > .row {
    padding: 1rem;

    display: flex;
    justify-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    > .subHeading {
      flex: 1;
      padding: 1rem;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
      white-space: nowrap;
      min-width: 24rem;
    }

    > .section {
      flex: 3;
      width: 100%;
      min-width: 28.125rem;
    }
  }
}
