@use '/src/utils/globals';

.ExportPage {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
  @media (max-width: globals.$collapse-panel-on-width) {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .FeedFloButton {
    .feedFloIcon {
      margin-top: 0.35em;
      margin-right: 0.4em;
    }
  }

  .h1 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;
    border-bottom: solid rgb(223, 223, 223) 1px;
  }

  .ExportController {
    padding-top: 1em;
    .rowContainer {
      display: flex;
      flex-direction: row;
      @media (max-width: globals.$collapse-panel-on-width) {
        flex-direction: column;
      }
      .cell {
        @media (max-width: globals.$collapse-panel-on-width) {
          width: 100%;
        }
        align-self: center;
        padding: 0.2em;
        min-width: 11em;

        &.flexGrow {
          flex-grow: 1;
        }
      }
    }
    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .dateRangeButton {
      display: flex;
      flex-direction: row;

      border: 0.063rem solid #d9dce1;
      border-radius: 0.25rem;
      width: fit-content;

      user-select: none;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 2px 5px;
      }
      div {
        padding: 0.25rem 0.75rem;
      }
    }
  }

  .TimeZoneMessage {
    font-size: 13px;
    margin-left: 1em;
    color: rgb(58, 58, 58);
  }
}
