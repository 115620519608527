.FeedFloSearchBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }

  .input-icons i {
    position: absolute;
    margin-top: -0.2rem;
  }
  .input-icons {
    width: 100%;
    margin-bottom: 0.625rem;
    > div input {
      // how we access searchbar properties
      border: 0.063rem solid #c4c4c4;
      border-radius: 0.25rem;
      height: 2rem;
      text-indent: 2rem;
      font-size: 1rem;
    }
  }

  .icon {
    padding: 0.625rem;
    min-width: 2.5rem;
  }

  .input-field {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0.625rem;
    justify-content: space-between;
    .feedFloIcon {
      margin-right: 1rem;
    }
  }

  label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.188rem;
  }

  .textBox {
    border: 0.063rem solid #c4c4c4;
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    font-size: 13px;
  }
}

.FeedFloSearchBar > .input-icons > div > div {
  max-height: 25rem;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  div {
    cursor: pointer;
  }
}
