.buttonHolder {
  margin: 0rem;
  padding: 0rem;
  padding-left: 0.5rem;
  padding-top: 1rem;

  li {
    padding: 0.25rem;
    padding-left: 0.5rem;
    margin: 0.25rem;
    border-radius: 0.3rem;
    line-height: 1.5rem;
    cursor: pointer;
    &:hover {
      background-color: rgb(233, 233, 233);
    }
    &.selected {
      .buttonText {
        color: #4b806f;
        font-weight: 600;
      }

      img {
        filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(112deg) saturate(600%) contrast(0.8);
      }
    }
    .feedFloIcon {
      vertical-align: middle;
      margin-bottom: 4px;
    }

    .buttonText {
      color: #1a1f36;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      padding-left: 1rem;
      display: inline-block;
    }
  }
}
