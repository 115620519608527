@use '/src/utils/globals';

.graphiql-container {
  border-top: 1px solid rgba(221, 221, 221, 0.619);
  * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -ms-box-sizing: content-box;
    box-sizing: content-box;
  }
  padding-bottom: 1rem;
}
.feedflo-graphiql-container {
  height: 81.8vh;
  border: 1px solid rgba(221, 221, 221, 0.619);
  border-radius: 6px;
}
.url {
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  color: #a3acb9;
}

.explorer-and-graphiQL {
  display: flex;
  height: inherit;
}

.topExplorerLine {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  padding: 2rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  .FeedFloTextInput {
    width: max-content;
  }
}

.feedflo-graphiql-container {
  .tokenStatusHolder {
    margin-left: auto;
    margin-top: 2.35rem;
    margin-right: 0.5rem;
  }
}
