.UnsavedChangesBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #e3e8ee;
  height: 3rem;
  padding: 0 1rem;

  &-text {
    flex: 1;
    margin: 0 0.5rem;
  }

  &-errorText {
    padding-right: 1em;
    margin: 0 0.5rem;
  }

  &-saveButton {
    margin: 0 0.5rem;
  }
}
