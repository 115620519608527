.DropCheckBox {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.188rem;
  }

  .sideBySide {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    padding: 0.2rem;
    font-size: 14px;
  }
  .checkbox {
    padding-right: 0.5rem;
    padding-top: 0.3rem;
  }
  .selectAll {
    border-bottom: 0.0625rem solid #c4c4c4;
  }

  .dropCheckBoxHeader {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border: 1px solid #c4c4c4;
    border-radius: 4px;
    cursor: pointer;
    .title {
      align-self: center;
      font-size: 13px;
      padding: 0.5rem;
    }

    .icon {
      align-self: center;
      margin: 0px 8px;

      &.showList {
        transform: rotate(180deg);
      }
    }
  }

  .list {
    position: absolute;
    width: 100%;
    z-index: 9;
    background-color: #fff;
    top: 2.3rem;
    white-space: normal;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 4px;

    .listItem {
      font-size: 14px;
      padding: 0.5rem;
      align-content: center;
    }

    .selected {
      background-color: #6fcf9750;
    }

    .listItem:hover {
      background-color: #6fcf97a1;
    }
  }
}
