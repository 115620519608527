.Heading {
  color: #1a1f36;
  font-weight: 400;
  margin-bottom: 0.5rem;
  font-size: 2rem;

  &--underline {
    padding-bottom: 1rem;
    margin-bottom: 0;
    border-bottom: 1px solid #dfdfdf;
  }
}
