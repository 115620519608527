.FeedFloTextInput {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .disabled {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }

  label {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.188rem;
  }

  .textBox {
    border: 0.063rem solid #c4c4c4;
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    font-size: 13px;
    height: 2rem;
    width: 100%;
  }

  .moveText {
    text-indent: 1.5rem;
  }

  .input-icons i {
    position: absolute;
    margin-top: -0.2rem;
  }
  .input-icons {
    width: 100%;
    margin-bottom: 0.625rem;
    position: relative;

    > div input {
      // how we access searchbar properties
      border: 0.063rem solid #c4c4c4;
      border-radius: 0.25rem;
      height: 2rem;
      text-indent: 2rem;
      font-size: 1rem;
      padding-left: 2rem;
    }
  }

  .icon {
    padding: 0.625rem;
    min-width: 2.5rem;
  }
}
