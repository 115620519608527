$outer-radius: 0.3rem;
$inner-radius: 0.2rem;

.FeedFloToggleButton {
  border: 0.1rem solid #d9dce1;
  background: #d9dce1;
  border-radius: $outer-radius;
  user-select: none;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 2px 5px;
  }

  div.toggle {
    background: #d9dce1;
    color: #3c425770;
  }

  div {
    background: #ffffff;

    font-weight: 500;
    align-items: center;
    color: #3c4257;
    box-sizing: border-box;
    padding: 0.2rem 0.4rem;
    display: inline-block;
  }

  div:first-child {
    border-bottom-left-radius: $inner-radius;
    border-top-left-radius: $inner-radius;
  }

  div:last-child {
    border-bottom-right-radius: $inner-radius;
    border-top-right-radius: $inner-radius;
  }
}
