@use '/src/utils/globals';

.FeedLinePage {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
  .topBar {
    position: absolute;
    display: flex;
    cursor: pointer;
    z-index: 1;
    margin-top: -4rem;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
    .icon {
      transform: rotate(90deg);
    }
  }

  .FeedLineConsumptionHolder .FeedFloChart {
    height: 20rem;
  }
  .h1 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;
    border-bottom: solid rgb(223, 223, 223) 1px;
  }
  .topHalf {
    margin-right: 0;
    display: flex;
    flex-direction: row;

    @media (max-width: globals.$collapse-panel-on-width) {
      flex-direction: column;
    }
    .topLeftSide {
      flex: 1.5;
      margin-top: 1.5rem;

      .headers {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        .dateRangeButton {
          display: flex;
          flex-direction: row;

          border: 0.063rem solid #d9dce1;
          border-radius: 0.25rem;
          width: fit-content;

          user-select: none;
          cursor: pointer;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 2px 5px;
          }
          div {
            padding: 0.25rem 0.75rem;
          }

          .icon {
            display: flex;
            align-self: center;
            padding-bottom: 0.438rem;
            padding-right: 0.25rem;
          }

          .text {
            color: #3c4257;
            font-weight: 500;
            padding-left: 0.25rem;
          }
        }
      }
      .headers > * {
        margin-right: 0.5rem;
      }
    }

    .topRightSide {
      flex: 1;
      .tiles > * {
        border-bottom: solid rgb(223, 223, 223) 1px;
        padding-top: 0.5rem;
        padding-bottom: 2rem;
      }
      .tiles > :last-child {
        border-bottom: none;
      }

      .statHeader {
        background-color: rgba(111, 207, 151, 0.08);
        font-weight: 600;
        padding-left: 1rem;
        padding-top: 2rem;
      }
    }

    margin: 0.219rem;

    .unit {
      font-size: 0.625rem;
      line-height: 0.761rem;
    }

    .title {
      font-size: 0.875rem;
      line-height: 1.066rem;
      font-weight: 600;
      color: #4f566b;
    }

    .value {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.523rem;
    }
  }

  .bottomHalf {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bottom-header-buttons {
      display: flex;
      flex-direction: row;
    }

    .bottom-header-buttons > * {
      margin-right: 0.7rem;
    }

    .icon {
      display: flex;
      align-self: center;
      justify-self: center;
    }

    .text {
      color: #3c4257;
      font-weight: 500;
      padding-left: 0.25rem;
    }
  }

  .tooltip {
    position: relative;
    display: inline-grid;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    word-wrap: break-word;
    background-color: black;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;

    /* Position the tooltip */
    position: absolute;
    right: 0.01rem;
    z-index: 3;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
